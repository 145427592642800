import { useCallback, useMemo, useState } from 'react';
import type { PWItem, RequestOptions } from '@bentley/pw-api';
import {
  addIsLockedFileStatusToItem,
  getFileLockedStatus,
  isValidDriveData
} from '../../actions/drive';
import {
  useConnectionAuth,
  useGraphApiContext,
  usePluginContext
} from '../../context';
import { usingConcurrencyLimiter } from '../../services/concurrencyLimiter';
import { useUpdateItems } from './useUpdateItems';
import type { SecondaryRequest } from '.';

type PWIntervalData = {
  intervalRequests: SecondaryRequest<PWItem>[];
  dataUpdateTrigger: number;
};

export function usePWIntervalData(): PWIntervalData {
  const { userId } = useConnectionAuth();
  const { contextId, pwDriveData } = usePluginContext();
  const { refreshCoAuthoringSessions } = useGraphApiContext();

  const { updateItemsFromServer } = useUpdateItems();

  const [dataUpdateTrigger, setDataUpdateTrigger] = useState<number>(0);

  const driveRefreshEnabled = useMemo((): boolean => {
    if (!isValidDriveData(pwDriveData) || !contextId || !userId) {
      return false;
    }
    return true;
  }, [contextId, pwDriveData, userId]);

  const addFileLockedStatusToItems = useCallback(
    async (items: PWItem[], httpOptions?: RequestOptions): Promise<void> => {
      if (!items.length || !contextId || !driveRefreshEnabled) {
        return;
      }

      await usingConcurrencyLimiter(async () => {
        const changedCoAuthoringSessionsIds =
          await refreshCoAuthoringSessions();

        const lockedInfo = await getFileLockedStatus(
          items,
          userId,
          pwDriveData.httpDriveService,
          contextId
        );

        const filesUpdated = items.filter((item) =>
          addIsLockedFileStatusToItem(item, lockedInfo)
        );

        if (filesUpdated?.length || changedCoAuthoringSessionsIds?.length) {
          const instanceIds = [
            ...(filesUpdated?.map((item) => item.instanceId) || []),
            ...(changedCoAuthoringSessionsIds || [])
          ];
          await updateItemsFromServer(instanceIds, items, httpOptions);
          setDataUpdateTrigger((cur) => cur + 1);
        }
      }, 'background');
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * refreshCoAuthoringSessions should not re-trigger effect
     */
    [
      contextId,
      driveRefreshEnabled,
      pwDriveData.httpDriveService,
      updateItemsFromServer,
      userId
    ]
  );

  const intervalRequests = useMemo(() => {
    const intervalRequests = [];

    if (driveRefreshEnabled) {
      intervalRequests.push(addFileLockedStatusToItems);
    }

    return { intervalRequests, dataUpdateTrigger };
  }, [addFileLockedStatusToItems, driveRefreshEnabled, dataUpdateTrigger]);

  return intervalRequests;
}
